import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuNav = _resolveComponent("MenuNav")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showComponents)
      ? (_openBlock(), _createBlock(_component_MenuNav, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showLoading)
      ? (_openBlock(), _createBlock(_component_LoadingComponent, {
          key: 1,
          hide: _ctx.fadeOutLoading
        }, null, 8, ["hide"]))
      : _createCommentVNode("", true),
    (_ctx.showComponents)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.showComponents)
      ? (_openBlock(), _createBlock(_component_FooterComponent, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}