
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderComponent",
  data() {
    return {
      titles: ["nejlepší", "juicy", "suprovou"],
      titlePaused: false,
      titleTime: 12,
      titleAdding: true,
      titleIndex: 0,
      title: "",
      titleLength: 0,
    };
  },
  mounted() {
    this.title = this.titles[0][0];
    setInterval(this.changeTitle, 75);
  },
  methods: {
    changeTitle: function () {
      if (this.titleAdding) {
        if (this.titlePaused) {
          if (this.titleTime > 0) {
            this.titleTime -= 1;
          } else {
            this.titleTime = 12;
            this.titleAdding = false;
            this.titlePaused = false;
          }
        } else {
          this.titleLength += 1;
        }
        if (
          this.titleLength === this.titles[this.titleIndex].length &&
          this.titleAdding
        ) {
          this.titlePaused = true;
        }
      } else {
        this.titleLength -= 1;
        if (this.titleLength === 0) {
          this.titleAdding = true;
          if (this.titleIndex < this.titles.length - 1) {
            this.titleIndex += 1;
          } else {
            this.titleIndex = 0;
          }
        }
      }
      this.title = this.titles[this.titleIndex].slice(0, this.titleLength);
    },
  },
});
