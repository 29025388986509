
import { defineComponent } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import { IItemModel } from "@/models/IItem.model";
import api from "../services/api-connection.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default defineComponent({
  name: "HomeView",
  data() {
    return {
      baguettesTypes: [] as IItemModel[],
    };
  },
  components: {
    HeaderComponent,
  },
  mounted() {
    api.getBaguetteTypes().then((res) => {
      this.baguettesTypes = res;
    });
  },
});
